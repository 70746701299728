// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCIhnqXnuGBNyZu8N0DZSv6KWfLKXpXz7I",
  authDomain: "msensev2-107a6.firebaseapp.com",
  projectId: "msensev2-107a6",
  storageBucket: "msensev2-107a6.appspot.com",
  messagingSenderId: "277922362869",
  appId: "1:277922362869:web:eb6be5ad2794edcbe75cbc",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const config = { db, auth };
export default config;
