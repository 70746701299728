import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import Dropdown from "./dropdown";
import RiderMarker from "./riderMarker";
import { useContext } from "react";
import { RiderDocContext } from "../contexts/riders";

const Riders = () => {
  const riderDocs = useContext(RiderDocContext);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [activeMarker, setActiveMarker] = useState(null);
  const [center, setCenter] = useState({ lat: 0.325, lng: 32.5857 });

  useEffect(() => {
    if (riderDocs.length > 0) {
      const lat = riderDocs.reduce((acc, riderDoc) => {
        return acc + riderDoc.currentLocation.latitude;
      }, 0);
      const lng = riderDocs.reduce((acc, riderDoc) => {
        return acc + riderDoc.currentLocation.longitude;
      }, 0);
      setCenter({ lat: lat / riderDocs.length, lng: lng / riderDocs.length });
    }
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: "100vh",
        width: "100%",
      }}
      center={center}
      zoom={11}
      onClick={() => setActiveMarker(null)}
      options={{
        streetViewControl: false,
        mapTypeId: "roadmap",
        mapTypeControl: false,
      }}
    >
      {riderDocs.length > 0 &&
        riderDocs
          .filter((riderDoc) => riderDoc.channelId !== "")
          .map((riderDoc) => (
            <RiderMarker
              key={riderDoc.id}
              riderDoc={riderDoc}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
            />
          ))}
      <Dropdown />
    </GoogleMap>
  ) : (
    <div className="justify-content-center align-items-center vh-100 d-flex">
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default Riders;
