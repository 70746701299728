import { useState } from "react";
import { Tooltip } from "react-tooltip";

const Dropdown = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <div
      style={{ position: "absolute", bottom: "10px", left: "10px" }}
      className="d-flex flex-column"
    >
      {isExpanded && (
        <ul
          className="mb-2 text-center rounded-5 border-0 bg-white p-2"
          style={{ width: "auto" }}
        >
          <li>
            <span
              className="material-symbols-outlined fs-1"
              style={{ color: "rgb(0,255,0)", cursor: "pointer" }}
              data-tooltip-id="sentiment_very_satisfied"
              data-tooltip-html="Air quality is good <br /> 0µg/m3 - 12µg/m3"
            >
              sentiment_very_satisfied
            </span>
            <Tooltip id="sentiment_very_satisfied" />
          </li>

          <li>
            <span
              className="material-symbols-outlined fs-1"
              style={{ color: " rgb(255,255,0)", cursor: "pointer" }}
              data-tooltip-id="mood"
              data-tooltip-html="
                Air quality is moderate <br />
                12.4µg/m3 - 25µg/m3
              "
            >
              mood
            </span>
            <Tooltip id="mood" />
          </li>
          <li>
            <span
              className="material-symbols-outlined fs-1"
              style={{ color: "rgb(255, 165, 0)", cursor: "pointer" }}
              data-tooltip-id="sentiment_satisfied"
              data-tooltip-html="
                  Air quality is unhealthy for sensitive groups <br />
                  25.5µg/m3 - 37.4µg/m3"
            >
              sentiment_satisfied
            </span>
            <Tooltip id="sentiment_satisfied" />
          </li>
          <li>
            <span
              className="material-symbols-outlined fs-1"
              style={{ color: "rgb(255,0,0)", cursor: "pointer" }}
              data-tooltip-id="sentiment_dissatisfied"
              data-tooltip-html="
                  Air quality is unhealthy <br />
                  37.5µg/m3 - 50µg/m3"
            >
              sentiment_dissatisfied
            </span>
            <Tooltip id="sentiment_dissatisfied" />
          </li>
          <li>
            <span
              className="material-symbols-outlined fs-1"
              style={{ color: "rgb(0,0,255)", cursor: "pointer" }}
              data-tooltip-id="mood_bad"
              data-tooltip-html="
                  Air quality is very unhealthy <br />
                  50.5µg/m3 - 90µg/m3"
            >
              mood_bad
            </span>
            <Tooltip id="mood_bad" />
          </li>
          <li>
            <span
              className="material-symbols-outlined fs-1"
              style={{ color: "rgb(238,130,238)", cursor: "pointer" }}
              data-tooltip-id="sentiment_very_dissatisfied"
              data-tooltip-html="
                  Air quality is hazardous <br />
                  90.5µg/m3 - 500µg/m3"
            >
              sentiment_very_dissatisfied
            </span>
            <Tooltip id="sentiment_very_dissatisfied" />
          </li>
        </ul>
      )}
      <button
        className="btn border-0 bg-white p-2 text-center rounded-circle"
        type="button"
        style={{ width: "50px", height: "50px" }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        {isExpanded ? (
          <span className="material-symbols-outlined  ">expand_more</span>
        ) : (
          <span className="material-symbols-outlined ">expand_less</span>
        )}
      </button>
    </div>
  );
};

export default Dropdown;
