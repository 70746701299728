import { useState } from "react";
import config from "../firebaseConfig";
import { setDoc, doc, GeoPoint } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";

const NewRider = () => {
  const { db, auth } = config;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [channelId, setChannelId] = useState("");

  const navigate = useNavigate();

  const createPassword = (firstName, lastName, phoneNumber) => {
    const first = firstName.trim().toLowerCase();
    const last = lastName.trim().toLowerCase();
    const password = `${phoneNumber.slice(-3)}@${first
      .charAt(0)
      .toUpperCase()}${first.charAt(1)}${last
      .charAt(0)
      .toUpperCase()}${last.charAt(1)}`;

    return password;
  };

  const createNewRider = async (e) => {
    e.preventDefault();
    const rider = {
      firstName,
      lastName,
      phoneNumber,
      channelId,
      hasActiveTask: false,
      currentLocation: new GeoPoint(0.3152, 32.5816),
    };

    const email = `${phoneNumber}@msense.site`;
    const password = createPassword(firstName, lastName, phoneNumber);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await setDoc(doc(db, "riders", userCredential.user.uid), rider);

      alert("Successfully added rider. Password is " + password);
      navigate(-1);
      return;
    } catch (e) {
      if (e.code === "auth/email-already-in-use") {
        alert("The phone number is already in use by another account.");
      } else {
        alert(e.message);
      }
      return;
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
      <button
        className="btn border-0 text-warning align-self-start"
        onClick={() => navigate(-1)}
      >
        <span className="material-symbols-outlined">arrow_back</span>
      </button>
      <div style={{ width: "300px" }}>
        <form
          onSubmit={createNewRider}
          className="rounded-3 shadow p-3 flex-1 "
        >
          <legend className="fs-4 text-center py-2">Create rider</legend>
          <div className="mb-3">
            <input
              required
              type="text"
              maxLength="7"
              minLength="7"
              value={channelId}
              onChange={(e) => {
                setChannelId(e.target.value);
              }}
              placeholder="Channel ID"
              className="form-control"
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              placeholder="First Name"
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              placeholder="Last Name"
              className="form-control"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="text"
              maxLength="10"
              minLength="10"
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              placeholder="Phone number"
              className="form-control"
              required
            />
          </div>
          <button
            type="submit"
            className="btn btn-warning text-white w-100  mt-1"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default NewRider;
