const FilterTasks = ({ setStartDate, setEndDate, startDate, endDate }) => {
  return (
    <div className="border rounded shadow d-flex align-items-center justify-content-center ps-3">
      <div className="d-flex flex-column">
        <label htmlFor="startDate" className="form-label fs-5">
          Start Date
        </label>
        <input
          type="date"
          id="startDate"
          value={startDate}
          className="form-control"
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
        />
      </div>
      <div className="d-flex flex-column ms-3 me-3">
        <label htmlFor="endDate" className="form-label fs-5">
          End Date
        </label>
        <input
          type="date"
          id="endDate"
          value={endDate}
          className="form-control"
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default FilterTasks;
