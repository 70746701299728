const TaskCard = ({ route, pay, date, id, selectedTask }) => {
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <div
      className="d-flex flex-column p-3 rounded bg-white"
      style={
        selectedTask === id
          ? { boxShadow: "0px 0px 5px 2px rgba(255,165,0, 0.5)" }
          : {}
      }
    >
      <div className="d-flex flex-wrap row-gap-2 column-gap-1">
        {route.map((point, index) => (
          <div key={`${id}_${index}`} className="px-2 py-1 bg-light rounded-3">
            {point}
          </div>
        ))}
      </div>
      <hr className="border border-light" />
      <div className="row">
        <div className="col-6">Pay :</div>
        <div className="col-6">{pay}</div>
      </div>
      <div className="row">
        <div className="col-6"> Date:</div>
        <div className="col-6">
          {date.toLocaleDateString("en-Us", dateOptions)}
        </div>
      </div>
      <div className="row">
        <div className="col-6">Time:</div>
        <div className="col-6">
          {date.toLocaleTimeString("en-Us", timeOptions)}
        </div>
      </div>
    </div>
  );
};

export default TaskCard;
