import { createContext, useState, useEffect, useContext } from "react";
import { RiderDocContext } from "./riders";
import {
  onSnapshot,
  collection,
  where,
  query,
  orderBy,
} from "firebase/firestore";
import config from "../firebaseConfig";

export const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const { db } = config;
  const [tasks, setTasks] = useState([]);
  const riderDocs = useContext(RiderDocContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (riderDocs.length > 0) {
      const promises = riderDocs.map((riderDoc) => {
        let riderTasksRef = collection(db, "riders", riderDoc.id, "tasks");

        if (startDate) {
          riderTasksRef = query(
            riderTasksRef,
            where("date", ">=", new Date(startDate))
          );
        }

        if (endDate && new Date(endDate) >= new Date(startDate)) {
          riderTasksRef = query(
            riderTasksRef,
            where("date", "<=", new Date(endDate))
          );
        }

        riderTasksRef = query(riderTasksRef, orderBy("date", "desc"));
        const { firstName, lastName } = riderDoc;
        return new Promise((resolve) => {
          onSnapshot(riderTasksRef, (snapshot) => {
            const taskDocs = snapshot.docs.map((doc) => {
              const taskDoc = doc.data();
              taskDoc.id = doc.id;
              return taskDoc;
            });

            resolve({
              tasks: taskDocs,
              id: riderDoc.id,
              firstName,
              lastName,
            });
          });
        });
      });
      Promise.all(promises).then((items) => {
        setTasks(items);
      });
    }
  }, [riderDocs, startDate, endDate, db]);

  return (
    <TaskContext.Provider
      value={{ tasks, setEndDate, setStartDate, startDate, endDate }}
    >
      {children}
    </TaskContext.Provider>
  );
};
