import { useState, useEffect } from "react";
import "./toggle.css";
const SampleSensorData = ({ setSample, data, toggleOn, setToggleOn }) => {
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    const filterData = () => {
      var sampleCopy = data;

      if (startDate) {
        if (startTime) {
          const datetime = Date.parse(`${startDate}T${startTime}`);
          sampleCopy = sampleCopy.filter((data_point) => {
            if (data_point.created_at) {
              return Date.parse(data_point.created_at) >= datetime;
            }
            return false;
          });
        } else {
          const datetime = Date.parse(`${startDate}T00:01:00`);
          sampleCopy = sampleCopy.filter((data_point) => {
            if (data_point.created_at) {
              return Date.parse(data_point.created_at) >= datetime;
            }
            return false;
          });
        }
        if (endDate) {
          if (endTime) {
            const datetime = Date.parse(`${endDate}T${endTime}`);
            sampleCopy = sampleCopy.filter((data_point) => {
              if (data_point.created_at) {
                return Date.parse(data_point.created_at) < datetime;
              }
              return false;
            });
          } else {
            const datetime = Date.parse(`${endDate}T23:59:59`);
            sampleCopy = sampleCopy.filter((data_point) => {
              if (data_point.created_at) {
                return Date.parse(data_point.created_at) < datetime;
              }
              return false;
            });
          }
        }
      } else {
        if (endDate) {
          if (endTime) {
            const datetime = Date.parse(`${endDate}T${endTime}`);
            sampleCopy = sampleCopy.filter((data_point) => {
              if (data_point.created_at) {
                return Date.parse(data_point.created_at) < datetime;
              }
              return false;
            });
          } else {
            const datetime = Date.parse(`${endDate}T23:59:59`);
            sampleCopy = sampleCopy.filter((data_point) => {
              if (data_point.created_at) {
                return Date.parse(data_point.created_at) < datetime;
              }
              return false;
            });
          }
        }
      }
      setSample(sampleCopy);
    };

    if (data) {
      filterData();
    }
  }, [startDate, startTime, endDate, endTime, data, setSample]);

  return (
    <div
      className="d-flex flex-column row-gap-3 bg-white p-3 rounded-3 shadow-sm"
      style={{ position: "absolute", left: "10px", top: "10px" }}
    >
      <div className="d-flex column-gap-5">
        <div>
          <div className="fs-5">Start date and time</div>
          <div className="mb-2">
            <input
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div>
            <input
              type="time"
              className="form-control"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className="fs-5">End date and time</div>
          <div className="mb-2">
            <input
              type="date"
              className="form-control"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div>
            <input
              type="time"
              className="form-control"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
            />
          </div>
        </div>
      </div>
      <label className="switch">
        <input
          type="checkbox"
          checked={toggleOn}
          onChange={() => setToggleOn(!toggleOn)}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default SampleSensorData;
