import { useContext } from "react";
import { Link } from "react-router-dom";
import { TaskContext } from "../contexts/tasks";
import { RiderDocContext } from "../contexts/riders";
import FilterTasks from "./filterTasks";
import RiderCard from "./riderCard";

const Tasks = () => {
  const { setEndDate, setStartDate, startDate, endDate } =
    useContext(TaskContext);
  const riderDocs = useContext(RiderDocContext);

  function toTitleCase(str) {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  return (
    <div className="mt-3 ms-3">
      <div className="d-flex column-gap-2 fs-1">
        <FilterTasks {...{ setStartDate, setEndDate, startDate, endDate }} />
        <Link
          to="/new_rider"
          className="text-decoration-none text-dark p-0"
          style={{ width: "fit-content" }}
        >
          <div className="row">
            <div className="col border border-light shadow rounded-3 d-flex flex-column p-3 ms-3 justify-content-center align-items-center">
              <span
                className="material-symbols-outlined fs-1"
                style={{ color: "rgba(255,165,0, 0.5)" }}
              >
                add
              </span>
              <p className="fs-5">add new rider</p>
            </div>
          </div>
        </Link>
      </div>
      <div className="row mt-3 row-gap-3">
        {riderDocs.length > 0 &&
          riderDocs.map((riderDoc) => {
            return (
              <div key={riderDoc.id} className="col-md-3">
                <RiderCard
                  riderPhone={riderDoc.phoneNumber}
                  riderName={toTitleCase(
                    `${riderDoc.firstName} ${riderDoc.lastName}`
                  )}
                  riderId={riderDoc.id}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Tasks;
