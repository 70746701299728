import { Link } from "react-router-dom";
// import sensorData from "../assets/sensors.json";

const Sensors = () => {
  // const jsonData = sensorData;
  const keys = Array.from({ length: 8 }, (_, i) => i + 1);

  // return (
  //   <div className="row row-gap-3 p-3">
  //     {Object.keys(jsonData).map((key) => (
  //       <Link
  //         to="/sensor_data"
  //         state={{ data: jsonData[key] }}
  //         className="text-decoration-none text-dark col-3"
  //         key={key}
  //       >
  //         <div className="card shadow p-3">
  //           <div className="card-body fs-5">Mobile Airqo Sensor {key}</div>
  //         </div>
  //       </Link>
  //     ))}
  //   </div>
  // );
  return (
    <div className="row row-gap-3 p-3">
      {keys.map((key) => (
        <Link
          to="/sensor_data"
          state={{ key }}
          className="text-decoration-none text-dark col-3"
          key={key}
        >
          <div className="card shadow p-3">
            <div className="card-body fs-5">Mobile Airqo Sensor {key}</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Sensors;
