import NavBar from "./componenets/navBar";
import Riders from "./componenets/riders";
import Tasks from "./componenets/tasks";
import RiderTasks from "./componenets/riderTasks";
import { Route, Routes } from "react-router-dom";
import NewRider from "./componenets/newRider";
import Sensors from "./componenets/sensors";
import SensorView from "./componenets/sensorView";

function MainContent() {
  return (
    <div className="d-flex">
      <NavBar />
      <hr className="border vh-100 border-light border-1 opacity-100"></hr>
      <div className="flex-grow-1">
        <Routes>
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/" element={<Riders />} />
          <Route path="/new_rider" element={<NewRider />}></Route>
          <Route path="/sensors" element={<Sensors />}></Route>
          <Route path="/sensor_data" element={<SensorView />}></Route>
          <Route path="/rider_tasks" element={<RiderTasks />}></Route>
        </Routes>
      </div>
    </div>
  );
}

export default MainContent;
