import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import { useEffect, useState, memo } from "react";
import markerGreen from "../assets/gps-green.png";
import markerYellow from "../assets/gps-yellow.png";
import markerOrange from "../assets/gps-orange.png";
import markerRed from "../assets/gps-red.png";
import markerBlue from "../assets/gps-blue.png";
import markerPurple from "../assets/gps-purple.png";
const SensorMarker = memo(
  ({ toggleOn, activeMarker, setActiveMarker, data_point }) => {
    const [isToggleOn, setIsToggleOn] = useState(toggleOn);
    const coordinates = data_point.field8.split(",");
    const lat = parseFloat(coordinates[0]);
    const lng = parseFloat(coordinates[1]);

    useEffect(() => {
      setIsToggleOn(toggleOn);
    }, [toggleOn]);

    return (
      data_point.field1 && (
        <MarkerF
          key={data_point.entry_id}
          position={{ lat, lng }}
          icon={{
            url: isToggleOn
              ? data_point.field1 <= 12
                ? markerGreen
                : data_point.field1 <= 25
                ? markerYellow
                : data_point.field1 <= 37.4
                ? markerOrange
                : data_point.field1 <= 50
                ? markerRed
                : data_point.field1 <= 90
                ? markerBlue
                : data_point.field1 >= 90.5
                ? markerPurple
                : markerRed
              : markerRed,
            scaledSize: new window.google.maps.Size(80, 50),
          }}
          onClick={() => {
            if (data_point.entry_id === activeMarker) {
              return;
            }
            setActiveMarker(data_point.entry_id);
          }}
        >
          {data_point.entry_id === activeMarker ? (
            <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
              <div className="d-flex flex-column pe-2 pb-2">
                <table className="table  table-hover">
                  <thead>
                    <tr>
                      <th rowSpan={2}>Sensors</th>
                      <th colSpan={2}>Pollutant concentration (µg/m3)</th>
                    </tr>
                    <tr>
                      <th>PM 10</th>
                      <th>PM 2.5</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>1</th>
                      <td>{data_point.field2}</td>
                      <td>{data_point.field1}</td>
                    </tr>
                    <tr>
                      <th>2</th>
                      <td>{data_point.field4}</td>
                      <td>{data_point.field3}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </InfoWindowF>
          ) : null}
        </MarkerF>
      )
    );
  }
);

export default SensorMarker;
