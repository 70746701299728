import { Link } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { TaskContext } from "../contexts/tasks";

const RiderCard = ({ riderName, riderPhone, riderId }) => {
  const [riderTasksLength, setRiderTasksLength] = useState(0);
  const { tasks: allRidersTasks } = useContext(TaskContext);

  useEffect(() => {
    if (allRidersTasks.length > 0) {
      const tasksDoc = allRidersTasks.find(
        (singleRiderTasks) => singleRiderTasks.id === riderId
      );
      setRiderTasksLength(tasksDoc.tasks.length);
    }
  }, [riderId, allRidersTasks]);

  return riderTasksLength > 0 ? (
    <Link
      to="/rider_tasks"
      state={{ riderId }}
      className="text-decoration-none text-dark"
    >
      <div className="d-flex flex-column shadow rounded-3 py-2 px-3 ">
        <span className="mb-1">Rider: {riderName}</span>
        <span className="mb-1">Phone: {riderPhone}</span>
        <span>Tasks: {riderTasksLength}</span>
      </div>
    </Link>
  ) : (
    <div className="d-flex flex-column shadow rounded-3 py-2 px-3 ">
      <span className="mb-1">Rider: {riderName}</span>
      <span className="mb-1">Phone: {riderPhone}</span>
      <span>Tasks: {riderTasksLength}</span>
    </div>
  );
};

export default RiderCard;
