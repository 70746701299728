import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RiderDocProvider } from "./contexts/riders";
import { TaskProvider } from "./contexts/tasks";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RiderDocProvider>
      <TaskProvider>
        <App />
      </TaskProvider>
    </RiderDocProvider>
  </React.StrictMode>
);
reportWebVitals();
