import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SampleSensorData from "./sampleSensorData";
import Dropdown from "./dropdown";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import SensorMarker from "./sensorMarker";
import _ from "lodash";

const SensorView = () => {
  const location = useLocation();
  const { key } = location.state || {};
  const [data, setData] = useState(null);

  const [sample, setSample] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [toggleOn, setToggleOn] = useState(false);
  const [map, setMap] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [center, setCenter] = useState({ lat: 0.3136, lng: 32.5811 });

  useEffect(() => {
    if (sample && sample.length > 0) {
      const lat = sample.reduce((acc, data_point) => {
        return acc + parseFloat(data_point.field8.split(",")[0]);
      }, 0);
      const lng = sample.reduce((acc, data_point) => {
        return acc + parseFloat(data_point.field8.split(",")[1]);
      }, 0);
      const newCenter = { lat: lat / sample.length, lng: lng / sample.length };
      setCenter(newCenter);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`https://data.msense.site/data?key=${key}`);
      const json = await response.json();
      setData(json["data"]);
    };
    fetchData();
  }, [key]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    if (mapBounds && data) {
      const filteredSample = data.filter(
        (data_point) =>
          data_point.field8 &&
          mapBounds.contains(
            new window.google.maps.LatLng(
              data_point.field8.split(",")[0],
              data_point.field8.split(",")[1]
            )
          )
      );
      setSample(filteredSample);
    }
  }, [mapBounds, data]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={{
        height: "100vh",
        width: "100%",
      }}
      center={center}
      zoom={15}
      onLoad={(map) => setMap(map)}
      options={{
        streetViewControl: false,
        mapTypeId: "roadmap",
        mapTypeControl: false,
      }}
      onBoundsChanged={() => {
        if (map) {
          const newBounds = map.getBounds();
          if (!_.isEqual(newBounds, mapBounds)) {
            setMapBounds(newBounds);
          }
        }
      }}
    >
      <SampleSensorData
        data={data}
        setSample={setSample}
        toggleOn={toggleOn}
        setToggleOn={setToggleOn}
      />
      {toggleOn && <Dropdown />}
      {sample &&
        sample.map((data_point) => (
          <SensorMarker
            key={data_point.entry_id}
            toggleOn={toggleOn}
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
            data_point={data_point}
          />
        ))}
    </GoogleMap>
  ) : (
    <div className="vh-100 w-100 d-flex justify-content-center align-items-center">
      <div className="spinner-border text-warning" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default SensorView;
