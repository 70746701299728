import { useState, useEffect, createContext } from "react";
import { onSnapshot, collection } from "firebase/firestore";
import config from "../firebaseConfig";

export const RiderDocContext = createContext();

export const RiderDocProvider = ({ children }) => {
  const { db } = config;
  const [riderDocs, setRiderDocs] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "riders"), (snapshot) => {
      const riderDocArray = snapshot.docs.map((doc) => {
        const riderDoc = doc.data();
        riderDoc.id = doc.id;
        return riderDoc;
      });
      setRiderDocs(riderDocArray);
    });
    return unsubscribe;
  }, [db]);

  return (
    <RiderDocContext.Provider value={riderDocs}>
      {children}
    </RiderDocContext.Provider>
  );
};
