import { BrowserRouter as Router } from "react-router-dom";
import MainContent from "./MainContent";
import "./App.css";
const App = () => {
  return (
    <Router>
      <MainContent />
    </Router>
  );
};

export default App;
