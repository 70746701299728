import { Link, useLocation } from "react-router-dom";

const NavBar = () => {
  const location = useLocation();
  const isSelected = (path) => {
    return path.includes(location.pathname);
  };
  const selectedStyle = {
    backgroundColor: "rgba(255, 165, 0, 0.1)",
    color: "rgb(255,165,0)",
  };

  return (
    <div className="me-3 ms-1 mt-5">
      <div className="d-flex flex-column">
        <div>
          <ul className="navbar-nav  mb-2 mb-lg-0 flex-column">
            <li className="d-flex align-items-center">
              <Link
                className="d-flex flex-column align-items-center border-0 p-1  btn fs-5 w-100 "
                style={
                  isSelected(["/", "/new_rider"])
                    ? selectedStyle
                    : { color: "RGB(100,100,100)" }
                }
                to="/"
              >
                <span className="material-symbols-outlined fs-1">
                  two_wheeler
                </span>
                <span>Riders</span>
              </Link>
            </li>
            <li className="d-flex align-items-center mt-3">
              <Link
                className="d-flex flex-column  align-items-center border-0 p-1 btn fs-5 w-100"
                style={
                  isSelected(["/tasks", "/rider_tasks"])
                    ? selectedStyle
                    : { color: "RGB(100,100,100)" }
                }
                to="/tasks"
              >
                <span className="material-symbols-outlined fs-1">work</span>
                <span>Tasks</span>
              </Link>
            </li>
            <li className="d-flex align-items-center mt-3">
              <Link
                className="d-flex flex-column  align-items-center border-0 p-1 btn fs-5 w-100"
                style={
                  isSelected(["/sensors", "/sensor_data"])
                    ? selectedStyle
                    : { color: "RGB(100,100,100)" }
                }
                to="/sensors"
              >
                <span className="material-symbols-outlined fs-1">nest_remote_comfort_sensor</span>
                <span>Sensors</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
