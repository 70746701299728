import TaskCard from "./taskCard";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import {
  GoogleMap,
  PolylineF,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { TaskContext } from "../contexts/tasks";
import isEqual from "lodash/isEqual";

const RiderTasks = () => {
  const location = useLocation();
  const { riderId } = location.state || {};
  const [riderTasks, setRiderTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [center, setCenter] = useState({ lat: 0.325, lng: 32.5857 });
  const [pathCoordinates, setPathCoordinates] = useState([]);
  const [map, setMap] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);

  const { tasks: allRidersTasks } = useContext(TaskContext);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const calculateCenter = (path) => {
    const lat = path.reduce((acc, point) => {
      return acc + point.lat;
    }, 0);
    const lng = path.reduce((acc, point) => {
      return acc + point.lng;
    }, 0);
    return { lat: lat / path.length, lng: lng / path.length };
  };

  useEffect(() => {
    if (allRidersTasks.length > 0) {
      const tasksDoc = allRidersTasks.find(
        (singleRidersTasks) => singleRidersTasks.id === riderId
      );
      if (!selectedTask) {
        setSelectedTask(tasksDoc.tasks[0].id);
      }
      if (!isEqual(tasksDoc.tasks, riderTasks)) {
        setRiderTasks(tasksDoc.tasks);
      }
    }
  }, [riderId, allRidersTasks, selectedTask, riderTasks]);

  useEffect(() => {
    if (pathCoordinates.length > 0 && isLoaded && map != null) {
      const bounds = new window.google.maps.LatLngBounds();

      bounds.extend(
        new window.google.maps.LatLng(
          pathCoordinates[0].lat,
          pathCoordinates[0].lng
        )
      );
      bounds.extend(
        new window.google.maps.LatLng(
          pathCoordinates[pathCoordinates.length - 1].lat,
          pathCoordinates[pathCoordinates.length - 1].lng
        )
      );

      setTimeout(() => {
        map.fitBounds(bounds);
      }, 100);
      setMap(map);
    }
  }, [pathCoordinates, isLoaded, map]);

  useEffect(() => {
    if (selectedTask) {
      var path = riderTasks.find((task) => task.id === selectedTask).pathTaken;
      path = path.map((point) => {
        return { lat: point._lat, lng: point._long };
      });
      setPathCoordinates(path);
      setCenter(calculateCenter(path));
    }
  }, [selectedTask, riderTasks]);

  return (
    <div className="row">
      <div className="px-3 py-2 bg-light col-4  overflow-y-auto vh-100">
        <ul className="p-0" style={{ listStyleType: "none" }}>
          {riderTasks.map((task) => {
            return (
              <li
                className="mb-2"
                style={{ cursor: "pointer" }}
                key={task.id}
                onClick={() => setSelectedTask(task.id)}
              >
                <TaskCard
                  id={task.id}
                  route={task.route}
                  selectedTask={selectedTask}
                  pay={task.pay.toLocaleString("en-US", {
                    style: "currency",
                    currency: "UGX",
                  })}
                  date={new Date(task.date.seconds * 1000)}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div className="col-8">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              height: "100vh",
              width: "100%",
            }}
            onClick={() => setActiveMarker(null)}
            center={center}
            onLoad={(map) => setMap(map)}
            options={{
              streetViewControl: false,
              mapTypeId: "roadmap",
              mapTypeControl: false,
            }}
          >
            <MarkerF
              position={pathCoordinates[0]}
              onClick={() => {
                if (activeMarker === "origin") {
                  return;
                }
                setActiveMarker("origin");
              }}
            >
              {activeMarker === "origin" && (
                <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                  <div className="me-3 mb-2">Task started here</div>
                </InfoWindowF>
              )}
            </MarkerF>
            <MarkerF
              position={pathCoordinates[pathCoordinates.length - 1]}
              onClick={() => {
                if (activeMarker === "destination") {
                  return;
                }
                setActiveMarker("destination");
              }}
            >
              {activeMarker === "destination" && riderTasks.length > 0 && (
                <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                  <div className="me-3 mb-2">
                    {riderTasks.find((task) => task.id === selectedTask).active
                      ? "Task is on-going"
                      : "Task ended here"}
                  </div>
                </InfoWindowF>
              )}
            </MarkerF>
            <PolylineF
              path={pathCoordinates}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 1.0,
                strokeWeight: 2,
              }}
            />
          </GoogleMap>
        ) : (
          <div className="vh-100 w-100 d-flex justify-content-center align-items-center">
            <div className="spinner-border text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RiderTasks;
